import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/industrials-transportation/*" />
    <RAC path="/zh-cn/industrials-transportation/*" />
    <RAC path="/zh-tw/industrials-transportation/*" />
    <RAC path="/pl/industrials-transportation/*" />
    <RAC path="/ru/industrials-transportation/*" />
    <RAC path="/it/industrials-transportation/*" />
    <RAC path="/de/industrials-transportation/*" />
    <RAC path="/es/industrials-transportation/*" />
    <RAC path="/fr/industrials-transportation/*" />
    <RAC path="/ar/industrials-transportation/*" />
    <RAC path="/id/industrials-transportation/*" />
    <RAC path="/pt/industrials-transportation/*" />
    <RAC path="/ko/industrials-transportation/*" />
    <DynamicNotFoundPage default />
  </Router>
)
